.icon-logo-bundesliga {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-bundesliga.png") no-repeat; height: 41px; width: 40px;}

.icon-facebook-logo-blue {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/facebook-logo-blue.png") no-repeat; height: 15px; width: 15px;}

.icon-flag-cze {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/flag-cze.png") no-repeat; height: 11px; width: 16px;}

.icon-logo-1-liga {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-1-liga.png") no-repeat; height: 69px; width: 91px;}

.icon-logo-elh-nopromo {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-elh-nopromo.png") no-repeat; height: 49px; width: 49px;}

.icon-logo-elh-tipsport {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-elh-tipsport.png") no-repeat; height: 55px; width: 57px;}

.icon-logo-ep-liga-small {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-ep-liga-small.png") no-repeat; height: 28px; width: 15px;}

.icon-logo-ep-liga {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-ep-liga.png") no-repeat; height: 55px; width: 30px;}

.icon-logo-elh {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-elh.png") no-repeat; height: 71px; width: 48px;}

.icon-logo-euro-2016 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-euro-2016.png") no-repeat; height: 68px; width: 68px;}

.icon-logo-digi {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-digi.png") no-repeat; height: 29px; width: 43px;}

.icon-logo-fantasy {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-fantasy.png") no-repeat; height: 75px; width: 84px;}

.icon-logo-goly-z-ligy {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-goly-z-ligy.png") no-repeat; height: 65px; width: 65px;}

.icon-logo-isport {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-isport.png") no-repeat; height: 65px; width: 65px;}

.icon-logo-iihf {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-iihf.png") no-repeat; height: 71px; width: 51px;}

.icon-logo-laliga {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-laliga.png") no-repeat; height: 55px; width: 55px;}

.icon-logo-liga-zive {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-liga-zive.png") no-repeat; height: 65px; width: 65px;}

.icon-logo-ms-2017 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-ms-2017.png") no-repeat; height: 75px; width: 51px;}

.icon-logo-pl-small {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-pl-small.png") no-repeat; height: 55px; width: 40px;}

.icon-logo-podcasty-65x65 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-podcasty-65x65.png") no-repeat; height: 65px; width: 65px;}

.icon-logo-premierleague {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-premierleague.png") no-repeat; height: 71px; width: 71px;}

.icon-logo-sazka-fantasy {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-sazka-fantasy.png") no-repeat; height: 57px; width: 133px;}

.icon-logo-synotliga {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-synotliga.png") no-repeat; height: 71px; width: 76px;}

.icon-logo-tym-snu {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-tym-snu.png") no-repeat; height: 85px; width: 80px;}

.icon-logo-uefa {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/logo-uefa.png") no-repeat; height: 80px; width: 66px;}

.icon-medail-green {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/medail-green.png") no-repeat; height: 22px; width: 18px;}

.icon-nissan-promo {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/nissan-promo.png") no-repeat; height: 28px; width: 152px;}

.icon-synottip {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/synottip.png") no-repeat; height: 28px; width: 95px;}

.icon-vertikala-banik-mala {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-banik-mala.png") no-repeat; height: 28px; width: 28px;}

.icon-vertikala-bg-slavia {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-bg-slavia.png") no-repeat; height: 310px; width: 360px;}

.icon-vertikala-bg-sparta {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-bg-sparta.png") no-repeat; height: 310px; width: 360px;}

.icon-vertikala-logo-banik {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-logo-banik.png") no-repeat; height: 182px; width: 182px;}

.icon-vertikala-logo-prestupy {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-logo-prestupy.png") no-repeat; height: 28px; width: 28px;}

.icon-vertikala-logo-slavia {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-logo-slavia.png") no-repeat; height: 182px; width: 182px;}

.icon-vertikala-logo-sparta {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-logo-sparta.png") no-repeat; height: 182px; width: 182px;}

.icon-vertikala-plzen-mala {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-plzen-mala.png") no-repeat; height: 28px; width: 28px;}

.icon-vertikala-slavia-mala {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-slavia-mala.png") no-repeat; height: 28px; width: 28px;}

.icon-vertikala-sparta-mala {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/vertikala-sparta-mala.png") no-repeat; height: 28px; width: 28px;}

.icon-bag {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/bag.png") no-repeat; height: 318px; width: 219px;}

.icon-btn-more-info {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/btn-more-info.png") no-repeat; height: 60px; width: 170px;}

.icon-btn-register {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/btn-register.png") no-repeat; height: 90px; width: 288px;}

.icon-favicon-32x32 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/favicon-32x32.png") no-repeat; height: 32px; width: 32px;}

.icon-favicon-96x96 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/favicon-96x96.png") no-repeat; height: 96px; width: 96px;}

.icon-header-columbia-logo {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/header-columbia-logo.png") no-repeat; height: 170px; width: 600px;}

.icon-header-isport-logo {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/header-isport-logo.png") no-repeat; height: 137px; width: 416px;}

.icon-header_bg {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/header_bg.jpg") no-repeat; height: 734px; width: 700px;}

.icon-icon-arrow {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/icon-arrow.PNG") no-repeat; height: 15px; width: 14px;}

.icon-icon-facebook {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/icon-facebook.png") no-repeat; height: 25px; width: 25px;}

.icon-icon-instagram {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/icon-instagram.png") no-repeat; height: 25px; width: 25px;}

.icon-icon-isport {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/icon-isport.png") no-repeat; height: 16px; width: 50px;}

.icon-favicon-16x16 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/favicon-16x16.png") no-repeat; height: 16px; width: 16px;}

.icon-medal {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/medal.png") no-repeat; height: 225px; width: 200px;}

.icon-shirt-bottom {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/shirt-bottom.png") no-repeat; height: 51px; width: 255px;}

.icon-shirt-middle {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/shirt-middle.png") no-repeat; height: 20px; width: 255px;}

.icon-shirt-top {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/shirt-top.png") no-repeat; height: 219px; width: 255px;}

.icon-superlife-logo {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/superlife-logo.png") no-repeat; height: 84px; width: 250px;}

.icon-superlife-logo-footer {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/superlife/superlife-logo-footer.png") no-repeat; height: 44px; width: 131px;}

.icon-footer-logo {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/footer-logo.gif") no-repeat; height: 90px; width: 211px;}

.icon-footer-logo2 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/footer-logo2.gif") no-repeat; height: 90px; width: 151px;}

.icon-headline {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/headline.jpg") no-repeat; height: 50px; width: 596px;}

.icon-het-small {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/het-small.png") no-repeat; height: 129px; width: 234px;}

.icon-het {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/het.jpg") no-repeat; height: 138px; width: 251px;}

.icon-ico-play {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/ico-play.png") no-repeat; height: 20px; width: 20px;}

.icon-logo {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/logo.gif") no-repeat; height: 85px; width: 252px;}

.icon-logo2-button {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/logo2-button.png") no-repeat; height: 43px; width: 252px;}

.icon-logo2 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/logo2.gif") no-repeat; height: 51px; width: 252px;}

.icon-logo2 {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/logo2.png") no-repeat; height: 51px; width: 252px;}

.icon-quote {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/quote.png") no-repeat; height: 58px; width: 333px;}

.icon-sign {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/sign.png") no-repeat; height: 55px; width: 215px;}

.icon-tomek {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/tomek.png") no-repeat; height: 159px; width: 98px;}

.icon-transparent {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/transparent.gif") no-repeat; height: 1px; width: 1px;}

.icon-coffee {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/special/coffee.jpg") no-repeat; height: 186px; width: 581px;}

.icon-coffee-coin {background: url("https://img2.cncenter.cz/images/isportcz/dist/non-svg/newsletter/isport-premium/special/coffee-coin.jpg") no-repeat; height: 189px; width: 581px;}